import Axios from 'axios'
import { useToast } from 'vue-toastification'
import { useRouter } from 'vue-router'

const toast = useToast()
const router = useRouter()

const axios = Axios.create({
    baseURL: import.meta.env.VITE_PUBLIC_BACKEND_URL,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
})

axios.interceptors.response.use(
    (response) => {
        return response
    },
    async (error) => {
        let timeout = null

        switch (error.response.status) {
            case 401:
                toast.info('Unauthorized. You will be redirected to login page.')

                timeout = setTimeout(() => {
                    router.push({ name: 'login' })
                    clearTimeout(timeout)
                }, 1000)

                break
            case 419:
                toast.info('Session expired. Please reload the page.')
                break
            case 429:
                toast.info(
                    'Slow down. It looks like you have done that too many times recently, please try that again later.'
                )
                break
            case 500:
                toast.error('An error occured. If the problem persists please contact the administrator.')
                break
        }

        return Promise.reject(error)
    }
)

export default axios
