import { useStoreAuthStore } from '@/store/store-auth'

export default function storeGuest({ to, next }) {
    const store = useStoreAuthStore()
    const storeAuthenticated = store.getIsAuthenticated

    if (to.name !== 'home' && storeAuthenticated) {
        return next({ name: 'home' })
    }

    return next()
}
