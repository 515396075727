import { defineStore } from 'pinia'
import axios from '@/lib/axios'
import { useStoreAuthStore } from './store-auth'
import { useAuthStore } from './auth'

export const useUserStore = defineStore({
    id: 'user',

    state: () =>
        JSON.parse(localStorage.getItem('@elemis:user')) ?? {
            id: null,
            name: 'guest',
            email: null,
            store: {},
        },

    actions: {
        updateState(payload) {
            const newUserState = { ...this.state, ...payload }
            localStorage.removeItem('@elemis:user')
            localStorage.setItem('@elemis:user', JSON.stringify(newUserState))
            this.$reset()
        },

        async fetchUser() {
            const { token } = useAuthStore()

            const { data } = await axios.get('/api/user', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            const userInfo = {
                id: data.id,
                name: data.name,
                email: data.email,
                store_id: data.store_id,
            }

            const { updateState } = useStoreAuthStore()

            await updateState({
                store_id: data.store.id,
                store_identifier: data.store.store_identifier,
                store_name: data.store.name,
                store_code: data.store.password,
                is_authenticated: false,
            })

            localStorage.setItem('@elemis:user', JSON.stringify(userInfo))
            this.$reset()
        },
    },
})
