export function useRandomString(length) {
    const start = 2
    return Math.random()
        .toString(36)
        .slice(start, length + start)
        .split('')
        .map((e) => (Math.random() < Math.random() ? e.toUpperCase() : e))
        .join()
        .replaceAll(',', '')
}
