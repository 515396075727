import { useAuthStore } from '@/store/auth'

export default function auth({ to, next }) {
    const { is_logged_in: authenticated } = useAuthStore()

    if (to.name !== 'login' && !authenticated) {
        return next({ name: 'login' })
    }

    return next()
}
