import { defineStore } from 'pinia'

export const useConnectionStatusStore = defineStore({
    id: 'connection-status',

    state: () => ({
        isOffline: !navigator.onLine,
        lastSync: JSON.parse(localStorage.getItem('@elemis:last-sync')) ?? null,
    }),

    actions: {
        updateIsOffline(payload) {
            this.isOffline = payload
        },

        updateLastSync(payload) {
            localStorage.removeItem('@elemis:last-sync')
            localStorage.setItem('@elemis:last-sync', JSON.stringify(payload))
        },
    },
})
