import { defineStore } from 'pinia'
import axios from '@/lib/axios'
import { useStoreVisitsStore } from './store-visits'
import { useRandomString } from '@/functions/useRandomString'
import { useAuthStore } from './auth'

export const useCustomersStore = defineStore({
    id: 'customers',

    state: () => ({
        customers: [],
        selectedCustomer: null,
    }),

    actions: {
        resetSelectedCustomer() {
            this.selectedCustomer = null
        },

        selectCustomer(customer) {
            const { upsertStoreVisit, updateTerms } = useStoreVisitsStore()

            this.selectedCustomer = customer

            let customerStoreVisitData = {}
            const questionOne = 'Any skin care concerns'
            const questionTwo = 'Any collection preferences'
            // const questionSeven = 'Do you have any allergies that may affect your skin?'
            // const questionEight =
            //     'Do you have any contra-indications or medical conditions that would affect your treatment?'
            // const questionNine = 'Are you pregnant?'

            // grab first two questions data from last store visit
            if (customer?.last_store_visit?.data[questionOne]) {
                customerStoreVisitData[questionOne] = customer.last_store_visit.data[questionOne]
            }

            if (customer?.last_store_visit?.data[questionTwo]) {
                customerStoreVisitData[questionTwo] = customer.last_store_visit.data[questionTwo]
            }

            // if (customer?.last_store_visit?.data[questionSeven]) {
            //     customerStoreVisitData[questionSeven] = customer.last_store_visit.data[questionSeven]
            // }

            // if (customer?.last_store_visit?.data[questionEight]) {
            //     customerStoreVisitData[questionEight] = customer.last_store_visit.data[questionEight]
            // }

            // if (customer?.last_store_visit?.data[questionNine]) {
            //     customerStoreVisitData[questionNine] = customer.last_store_visit.data[questionNine]
            // }

            if (customer?.last_store_visit) {
                upsertStoreVisit({
                    data: customerStoreVisitData,
                })

                updateTerms(customer.last_store_visit.terms)
            }
        },

        async fetchCustomers(params) {
            const { token } = useAuthStore()

            const { data } = await axios.get('/api/customers', {
                params,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            this.customers = data
        },

        setCustomerData({
            first_name,
            last_name,
            email,
            phone_number,
            birth_date,
            address,
            marketing_consent,
            health_consent,
            cryo_health_consent,
            last_store_visit,
        }) {
            const tempId = useRandomString(16)

            const customerData = {
                first_name,
                last_name,
                email,
                phone_number,
                birth_date,
                address,
                id: tempId,
                marketing_consent: marketing_consent,
                health_consent: health_consent,
                cryo_health_consent: cryo_health_consent,
            }

            if (last_store_visit) {
                customerData['last_store_visit'] = last_store_visit
            }

            this.selectCustomer(customerData)
        },
    },
})
