import { defineStore } from 'pinia'
import { useCustomersStore } from './customers'
import { useStoreAuthStore } from './store-auth'
import { useProductsStore } from './products'
import { useConnectionStatusStore } from './connection-status'
import { useAuthStore } from './auth'

export const useStoreVisitsStore = defineStore({
    id: 'store-visits',

    state: () => ({
        store_id: null,
        customer_id: null,
        terms: null,
        data: {},
        products: [],
    }),

    actions: {
        resetStoreVisitData() {
            this.$reset()
        },

        updateTerms(terms) {
            this.terms = terms
        },

        upsertStoreVisit({ data }) {
            this.data = { ...this.data, ...data }
        },

        async persistStoreVisit() {
            const { selectedProducts } = useProductsStore()
            const { selectedCustomer } = useCustomersStore()
            const storeAuthStore = useStoreAuthStore()
            const { token } = useAuthStore()

            this.customer_id = selectedCustomer?.id
            this.store_id = storeAuthStore.store_id

            const products = selectedProducts.map((p) => {
                return {
                    product_barcode: p.barcode,
                    quantity: p.quantity,
                }
            })

            const customerData = Object.assign(
                {},
                selectedCustomer
                    ? {
                          first_name: selectedCustomer.first_name,
                          last_name: selectedCustomer.last_name,
                          email: selectedCustomer.email,
                          phone_number: selectedCustomer.phone_number,
                          birth_date: selectedCustomer.birth_date,
                          address: selectedCustomer.address,
                          marketing_consent: selectedCustomer.marketing_consent,
                          health_consent: selectedCustomer.health_consent,
                          cryo_health_consent: selectedCustomer.cryo_health_consent,
                      }
                    : {}
            )

            const storeVisitData = Object.assign(
                {},
                {
                    store_id: this.store_id,
                    terms: this.terms ? true : false,
                    data: this.data,
                    products,
                }
            )

            const response = await fetch(`${import.meta.env.VITE_PUBLIC_BACKEND_URL}/api/store-visits`, {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify({ ...storeVisitData, ...{ customer: customerData } }),
            })

            if (response.ok) {
                const { updateLastSync } = useConnectionStatusStore()
                updateLastSync(new Date())
            }
        },
    },
})
