import { defineStore } from 'pinia'
import axios from '@/lib/axios'
import { useAuthStore } from './auth'

export const useProductsStore = defineStore({
    id: 'products',

    state: () => ({
        selectedProducts: [],
    }),

    actions: {
        resetProductData() {
            this.$reset()
        },

        addProduct(product) {
            const existingProduct = this.selectedProducts.find((p) => p.barcode === product.barcode)
            if (existingProduct) {
                existingProduct.quantity++
            } else {
                this.selectedProducts.push({ ...product, quantity: 1 })
            }
        },

        incrementProductQuantity(barcode) {
            const product = this.selectedProducts.find((p) => p.barcode === barcode)
            product.quantity++
        },

        decrementProductQuantity(barcode) {
            const product = this.selectedProducts.find((p) => p.barcode === barcode)
            product.quantity--

            if (product.quantity === 0) {
                this.removeProduct(barcode)
            }
        },

        removeProduct(barcode) {
            const productIndex = this.selectedProducts.findIndex((p) => p.barcode === barcode)
            this.selectedProducts.splice(productIndex, 1)
        },

        async fetchProduct({ barcode }) {
            try {
                const { token } = useAuthStore()
                const { data } = await axios.get('/api/search-product', {
                    params: {
                        barcode,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })

                this.addProduct(data)
            } catch (error) {
                this.addProduct({
                    barcode: barcode,
                    quantity: 1,
                })
            }
        },
    },
})
