import { useCustomersStore } from '@/store/customers'

export default function customerSelected({ to, next }) {
    const { selectedCustomer } = useCustomersStore()

    if (to.name !== 'home' && !selectedCustomer) {
        return next({ name: 'home' })
    }

    return next()
}
