import { useAuthStore } from '@/store/auth'

export default function guest({ to, next }) {
    const { is_logged_in: authenticated } = useAuthStore()

    if (to.name !== 'home' && authenticated) {
        return next({ name: 'home' })
    }

    return next()
}
