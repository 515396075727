import { defineStore } from 'pinia'
import encryptStorage from '@/functions/useEncryptStorage'
const lockTime = import.meta.env.DEV ? 5 : 10
const lockTTL = lockTime * 60 * 1000 // 10 minutes: 10 * 60 * 1000

export const useStoreAuthStore = defineStore({
    id: 'store',

    state: () => {
        return {
            store_id: null,
            store_identifier: null,
            store_name: null,
            store_code: null,
            is_authenticated: false,
            expiry: null,
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                storage: encryptStorage,
            },
        ],
    },
    getters: {
        getIsAuthenticated() {
            const now = new Date()
            // compare the expiry time of the item with the current time
            if (this.expiry === null || now.getTime() > this.expiry) {
                // If the item is expired, delete the item from storage
                // and return null
                this.updateIsAuthenticated(false)
                return null
            }

            return this.is_authenticated
        },
    },
    actions: {
        updateState(payload) {
            this.store_id = payload.store_id
            this.store_identifier = payload.store_identifier
            this.store_name = payload.store_name
            this.store_code = payload.store_code
            this.updateIsAuthenticated(payload.is_authenticated)
        },

        async resetExpiry() {
            this.updateExpiry()
        },

        updateIsAuthenticated(payload) {
            this.is_authenticated = payload

            if (this.is_authenticated) {
                this.updateExpiry()
            } else {
                this.expiry = null
            }
        },

        updateExpiry() {
            const now = new Date()
            this.expiry = now.getTime() + lockTTL
        },

        authenticate({ password }) {
            if (password !== this.store_code) {
                throw new Error('Invalid password')
            }

            this.updateIsAuthenticated(true)
        },

        deauthenticate() {
            this.updateIsAuthenticated(false)
        },
    },
})
