import { defineStore } from 'pinia'
import axios from '@/lib/axios'
import { useAuthStore } from './auth'

export const useQuestionsStore = defineStore({
    id: 'questions',

    state: () => ({
        questions: JSON.parse(localStorage.getItem('@elemis:questions')) ?? [],
    }),

    actions: {
        updateState(payload) {
            const newQuestionsState = { ...this.$state.questions, ...payload }
            localStorage.removeItem('@elemis:questions')
            localStorage.setItem('@elemis:questions', JSON.stringify(newQuestionsState))
            this.$reset()
        },

        async fetchQuestions(params) {
            const { token } = useAuthStore()
            const { data } = await axios.get('/api/questions', {
                params,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            this.updateState(data)
        },
    },
})
