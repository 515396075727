import { useStoreAuthStore } from '@/store/store-auth'

export default function storeAuthenticated({ to, next }) {
    const store = useStoreAuthStore()
    const storeAuthenticated = store.getIsAuthenticated

    if (to.name !== 'staff-login' && !storeAuthenticated) {
        return next({ name: 'staff-login' })
    }

    return next()
}
