import { createRouter, createWebHistory } from 'vue-router'
import auth from './middleware/auth'
import storeAuthenticated from './middleware/store-authenticated'
import guest from './middleware/guest'
import storeGuest from './middleware/store-guest'
import middlewarePipeline from './middleware/middleware-pipeline'
import customerSelected from './middleware/customer-selected'

const routes = [
    {
        path: '/auth/azure/callback',
        name: 'azure-callback',
        component: () => import('../pages/AzureCallback.vue'),
        meta: {
            middleware: [guest],
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/StoreLogin.vue'),
        meta: {
            middleware: [guest],
        },
    },
    {
        path: '/staff-login',
        name: 'staff-login',
        component: () => import('../pages/StaffLogin.vue'),
        meta: {
            middleware: [storeGuest, auth],
        },
    },
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/CustomerLookup.vue'),
        meta: {
            middleware: [auth, storeAuthenticated],
        },
    },
    {
        path: '/customer-confirmation',
        name: 'customer-confirmation',
        component: () => import('../pages/CustomerConfirmation.vue'),
        meta: {
            middleware: [auth, storeAuthenticated, customerSelected],
        },
    },
    {
        path: '/manage-customer',
        name: 'manage-customer',
        component: () => import('../pages/ManageCustomerDetails.vue'),
        meta: {
            middleware: [auth, storeAuthenticated],
        },
    },
    {
        path: '/questions/:id',
        name: 'questions',
        component: () => import('../pages/QuestionPage.vue'),
        meta: {
            middleware: [auth, storeAuthenticated, customerSelected],
        },
    },
    {
        path: '/health-consent',
        name: 'health-consent',
        component: () => import('../pages/HealthConsent.vue'),
        meta: {
            middleware: [auth, storeAuthenticated, customerSelected],
        },
    },
    {
        path: '/cryo-health-consent',
        name: 'cryo-health-consent',
        component: () => import('../pages/CryoHealthConsentPageOne.vue'),
        meta: {
            middleware: [auth, storeAuthenticated, customerSelected],
        },
    },
    {
        path: '/cryo-health-consent-2',
        name: 'cryo-health-consent-2',
        component: () => import('../pages/CryoHealthConsentPageTwo.vue'),
        meta: {
            middleware: [auth, storeAuthenticated, customerSelected],
        },
    },
    {
        path: '/cryo-health-consent-3',
        name: 'cryo-health-consent-3',
        component: () => import('../pages/CryoHealthConsentPageThree.vue'),
        meta: {
            middleware: [auth, storeAuthenticated, customerSelected],
        },
    },
    {
        path: '/remove-cryo-treatments',
        name: 'remove-cryo-treatments',
        component: () => import('../pages/RemoveCryoTreatments.vue'),
        meta: {
            middleware: [auth, storeAuthenticated, customerSelected],
        },
    },
    {
        path: '/product-input',
        name: 'product-input',
        component: () => import('../pages/ProductInput.vue'),
        meta: {
            middleware: [auth, storeAuthenticated /*, customerSelected */],
        },
    },
    {
        path: '/complete',
        name: 'complete',
        component: () => import('../pages/VisitComplete.vue'),
        meta: {
            middleware: [auth, storeAuthenticated /*,, customerSelected*/],
        },
    },
    { path: '/:pathMatch(.*)*', component: () => import('../pages/PageNotFound.vue') },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    /** Navigate to next if middleware is not applied */
    if (!to.meta.middleware) {
        return next()
    }

    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next,
    }

    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1),
    })
})

export default router
